import { Constants } from '@yescapa-dev/ysc-api-js/legacy'

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return
  const { form } = storeToRefs(useBookingRequestFunnelStore())
  const { camper } = storeToRefs(useCamperStore())
  if (
    form.value.travelers === null
    || form.value.purpose === null
    || (camper.value?.abroad_allowed !== Constants.PRODUCTS.OWNER_ACCEPTANCE_STATUS.NOT_ALLOWED && form.value.countries.length === 0)
    || form.value.phone === null
  ) {
    return navigateTo({ name: 'campers-id-request-travel-details', params: to.params, query: to.query })
  }
})
